import React from 'react'
import Advisor from './Advisor'

export const Norway = () => {
  return (
    <React.Fragment>
      <Advisor
        img="tom.png"
        name="Tom Nordvang"
        position="Gründer/styreleder"
        phone="+47 99 70 81 61"
        email="tom.nordvang@costpartner.no"
        description="Gründer/styreleder. Master i økonomi og  lederbakgrunn fra  bl.a. 3M og consulting-selskaper."
      />
      <Advisor
        img="espen.png"
        name="Espen Orderud"
        position="Daglig leder"
        phone="+47 93 05 04 50"
        email="espen.orderud@costpartner.no"
        description="Daglig leder. Erfaring som adm. dir. Caribonum Norway, dir salg og marked Emo, kjedeleder Emo Kontor, salgsdir Esselte og flere styreverv."
      />
      <Advisor
        img="kurt.png"
        name="Kurt Petter Lindgren"
        position="Seniorrådgiver"
        phone="+47 90 03 61 25"
        email="kurt.petter.lindgren@costpartner.no"
        description="Siviløkonom. Bred internasjonal erfaring innen økonomiledelse, bl.a Vice President Finance i Grieg International, CFO i Sunde Gruppen og Finance Director i MSD Norge."
      />
      <Advisor
        img="anders.png"
        name="Anders Bull-Gustavsen"
        position="Analytiker/rådgiver"
        phone="+47 99 20 22 53"
        email="anders.bull-gustavsen@costpartner.no"
        description="BSc i Supply Chain Management med spisskompetanse innen logistikk-analyse. Bred erfaring fra både analyse og konkurranseutsetting innen norsk og internasjonal transport. Anders kommer sist fra stilling som Transport Manager i Otra Norge AS."
      />
    </React.Fragment>
  )
}

export const Sweeden = () => {
  return (
    <React.Fragment>
      <Advisor
        img="per-erik.png"
        name="Per-Erik Påhlman"
        position="Partner/VD"
        phone="+46 722 23 80 80"
        email="per-erik.pahlman@cost-partner.se"
        description="Erfaren ekonom med gedigen erfarenhet som företagsledare i varierande branscher. Tidigare roller inkluderar bl.a. VD i HJ Hansen Sverige AB, VD Concordia Bus A/S, VD Stena Transport AB samt Affärsutvecklingschef SITA Sverige AB."
      />
    </React.Fragment>
  )
}
